import { Box, BoxProps, Chip, Stack } from '@mui/material';
import log from 'loglevel';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useFormController } from '../controller/form-controller';

export type FileDropzoneValue = {
  id: string;
  file: File;
  isValid: boolean;
}

export type FileDropzoneProps = {
  name: string;
  multiple?: boolean;
  sx?: BoxProps['sx'];
  dropzoneOptions?: Omit<DropzoneOptions, 'onDropAccepted'>;
  hideFiles?: boolean;
}

export function FileDropzone(props: FileDropzoneProps) {
  const {
    name,
    multiple = false,
    sx,
    dropzoneOptions,
    hideFiles = false,
  } = props;

  const { control } = useFormController();
  const { fields, replace } = useFieldArray({
    name,
    control,
  });
  const [rejected, setRejected] = React.useState<FileDropzoneValue[]>([]);

  const onDropAccepted = React.useCallback((accepted: File[]) => {
    const list: FileDropzoneValue[] = accepted.map((a) => ({
      id: uuidv4(),
      file: a,
      isValid: true,
    }));
    replace(list);
  }, [replace]);

  const onDropRejectedDefault = React.useCallback((rej: any[], event?: any) => {
    if (rej.length > 0) {
      log.error('rejected files', rej);
      setRejected(rej.map((r) => ({
        id: uuidv4(),
        file: r.file,
        isValid: false,
      })));
    }
    if (dropzoneOptions?.onDropRejected) {
      dropzoneOptions.onDropRejected(rej, event);
    }
  }, [dropzoneOptions]);

  const onDelete = React.useCallback((id: string) => () => {
    setRejected((list) => list.filter((x) => x.id !== id));
    const list = (fields as FileDropzoneValue[]).filter((x) => x.id !== id);
    replace(list);
  }, [replace, fields]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    ...dropzoneOptions,
    onDropRejected: onDropRejectedDefault,
    onDropAccepted,
  });

  const files = React.useMemo(() => {
    const list = [...fields as FileDropzoneValue[], ...rejected];
    return list.map((v) => {
      return (
        <Chip
          sx={{ mr: '5px', mb: '5px' }}
          key={v.id}
          label={v.file.name}
          variant="outlined"
          onDelete={onDelete(v.id)}
          color={v.isValid ? undefined : 'error'}
        />
      );
    });
  }, [fields, rejected, onDelete]);

  return (
    <Box
      sx={{
        minHeight: '100px',
        width: '100%',
        border: '1px solid #dddddd',
        ...sx,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          minHeight: '100px',
          width: '100%',
        }}
      >
        <Box>
          {
            isDragActive ?
              <p>Drop the file/s here ...</p> :
              <p>Drag 'n' drop some file/s here, or click to select files</p>
          }
        </Box>
        {hideFiles ? undefined : (
          <Box>
            {files}
          </Box>
        )}
      </Stack>
    </Box >
  )
}
