import { ColumnDef } from '@tanstack/react-table';
import { LocationResponse } from '../../apis/location.api';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {

  const columns: ColumnDef<LocationResponse>[] = [
    {
      accessorKey: 'stationCode',
      header: "Code",
    },
    {
      accessorKey: 'stationName',
      header: "Station Name",
    },
    {
      accessorKey: 'subsidiary',
      header: "Subsidiary",
    },
    {
      accessorKey: 'shift1Start',
      header: "Shift 1 Start",
    },
    {
      accessorKey: 'shift1End',
      header: "Shift 1 End",
    },
    {
      accessorKey: 'shift2Start',
      header: "Shift 2 Start",
    },
    {
      accessorKey: 'shift2End',
      header: "Shift 2 End",
    },
    {
      accessorKey: 'shift3Start',
      header: "Shift 3 Start",
    },
    {
      accessorKey: 'shift3End',
      header: "Shift 3 End",
    },
  ];

  return columns;
}
