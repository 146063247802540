import { Box, Button, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Intro from 'components/commons/intro/intro';
import log from 'loglevel';
import papa, { ParseResult } from 'papaparse';
import React from 'react';
import { Link } from 'react-router-dom';
import { getLocationList, updateLocation } from '../../apis/location.api';
import { BackLink } from '../../components/commons/back-link/back-link';
import { DataTableStandalone } from '../../components/commons/data-table/data-table-standalone';
import { FormController } from '../../components/commons/form/controller/form-controller';
import { FileDropzone } from '../../components/commons/form/file-upload/file-dropzone';
import Path from '../../enums/path';
import { ImportCsvFormat, UpdateLocationRecordWithStatus, UploadStatusEnum, useColumns } from './location-import-column';

export function LocationImportModule() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [changes, setChanges] = React.useState<UpdateLocationRecordWithStatus[]>([]);
  const columns = useColumns();

  const onSubmit = React.useCallback(async (values: any) => {
    setError(null);
    setLoading(true);
    const updates = values.files[0];
    const csvParsing = new Promise<ParseResult<ImportCsvFormat>>((resolve, reject) => {
      papa.parse<ImportCsvFormat>(updates.file, {
        header: true,
        complete: (result) => resolve(result),
        error: (error) => reject(error),
        skipEmptyLines: true,
      });
    });
    const result = await csvParsing
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        return undefined;
      });

    if (!result) return;
    const importData: UpdateLocationRecordWithStatus[] = result.data.map((x) => ({
      locationId: 0,
      stationCode: x['Station Code'],
      stationName: x['Station Name'],
      subsidiary: x['Entity'],
      shiftCount: parseInt(x['Number of Shifts'] || '0', 10),
      shift1Start: x['Shift 1 Start'] || "00:00",
      shift1End: x['Shift 1 End'] || "00:00",
      shift2Start: x['Shift 2 Start'] || null,
      shift2End: x['Shift 2 End'] || null,
      shift3Start: x['Shift 3 Start'] || null,
      shift3End: x['Shift 3 End'] || null,
      status: UploadStatusEnum.PENDING,
    }));

    importData.sort((a, b) => a.stationCode.localeCompare(b.stationCode));

    setChanges(importData);

    const stationCodeIn = importData.map((x) => x.stationCode).filter((x) => x) as string[];
    const list = await getLocationList({ query: { stationCodeIn } })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        return undefined;
      });
    if (!list) return;

    // match location id
    const ch: UpdateLocationRecordWithStatus[] = importData.map((r) => {
      const match = list.data.data.records
        .find((x) => x.stationCode === r.stationCode && x.subsidiary === r.subsidiary);
      if (!match) {
        return {
          ...r,
          locationId: 0,
          status: UploadStatusEnum.NO_MATCH,
        };
      }

      let hasChanged = r.shiftCount !== match.shiftCount;
      [
        'shift1Start',
        'shift1End',
        'shift2Start',
        'shift2End',
        'shift3Start',
        'shift3End',
      ].forEach((key) => {
        if (hasChanged) return;
        const time = String((match as any)[key] || '')
          .replace(/^([0-9]{2}\:[0-9]{2}).*?$/g, '$1');
        hasChanged = hasChanged || String((r as any)[key] || '') !== time;
      });
      if (!hasChanged) {
        return {
          ...r,
          locationId: match.locationId,
          status: UploadStatusEnum.NO_CHANGE,
        };
      }

      return {
        ...r,
        locationId: match.locationId,
        status: UploadStatusEnum.PENDING,
      };
    });

    setChanges(ch);

    const uResult = await updateLocation({
      records: ch.filter((x) => x.status === UploadStatusEnum.PENDING)
    })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        return undefined;
      });
    if (!uResult) return;

    log.info('update result', uResult.data.data);
    const ch2 = ch.map((c) => {
      const chr = { ...c };
      const err = uResult.data.data.errors.find((r) => r.locationId === chr.locationId);
      if (err) {
        chr.status = UploadStatusEnum.ERROR;
        chr.error = err.message;
      }
      if (chr.status === UploadStatusEnum.PENDING) {
        chr.status = UploadStatusEnum.UPDATED;
      }
      const count = ch.filter((x) => x.locationId === chr.locationId && x.locationId !== 0).length;
      if (count > 1) {
        chr.error = `Warning: This import contains ${count} records with the same location id.`
          + ` It could cause data inconsistency`;
      }
      if (chr.status === UploadStatusEnum.NO_MATCH) {
        chr.error = `This record was not updated. It should match station code and subsidiary with existing records`;
      }
      return chr;
    });
    setChanges(ch2);
    setLoading(false);
  }, []);

  return (
    <>
      <BackLink list={[{ text: 'Location', path: Path.Location }]} />
      <Intro title="Update Location" subtitle="Batch update stations records" />
      <Box sx={{ textAlign: 'right', p: '5px' }}>
        <Link to={'https://docs.google.com/spreadsheets/d/19tm98DtJhWYQmDF42HGGTYjbePkRApQSuSHU6CIbwWk'} target="_blank">Latest Template</Link>
      </Box>
      <FormController onSubmit={onSubmit}>
        <Stack direction='column' spacing={2}>
          <FileDropzone
            name='files'
            multiple={false}
            dropzoneOptions={{
              disabled: loading,
              accept: {
                'text/plain': ['.csv'],
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.csv'],
              },
            }}
          />
          <Button type='submit' disabled={loading}>Submit</Button>
          {error && <Alert severity="error">{`An error occurred: ${error}`}</Alert>}
        </Stack>
      </FormController>
      {changes.length > 0 && (
        <DataTableStandalone
          data={changes}
          columns={columns}
        />
      )}
    </>
  );
}