import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Grid, Stack } from '@mui/material';
import { DataTable } from 'components/commons/data-table/data-table';
import { DataTableControl } from 'components/commons/data-table/data-table-control';
import { DataTablePagination } from 'components/commons/data-table/data-table-pagination';
import { DataTableSearchBox } from 'components/commons/data-table/data-table-search-box';
import Intro from 'components/commons/intro/intro';
import { useNavigate } from 'react-router-dom';
import { getLocationList } from '../../apis/location.api';
import Path from '../../enums/path';
import { useColumns } from './location-list-column';
import { initialFilterState } from './location-list-filter.state';

export type LocationListProps = {};

export function LocationListModule(props: LocationListProps) {
  const columns = useColumns();
  const navigate = useNavigate();

  return (
    <>
      <Intro
        title="Location Master List"
        subtitle=""
      />
      <DataTableControl
        columns={columns}
        listFn={getLocationList}
        initialApiProps={initialFilterState}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <DataTableSearchBox autoFocus />
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => navigate(Path.LocationImport)}
                  startIcon={<FileUploadIcon />}
                >
                  Import File
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <DataTable />

          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
