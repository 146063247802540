import { DefaultLayout } from '../components/layout/twentyfour/default';
import { LocationListModule } from '../modules/location/location-list.module';

export function LocationPage() {
  return (
    <DefaultLayout>
      <LocationListModule />
    </DefaultLayout>
  )
}
