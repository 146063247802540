import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import Intro from 'components/commons/intro/intro';
import log from 'loglevel';
import moment from 'moment-timezone';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getTransactionImport, importAggregator } from '../../apis/aggregator.api';
import { BackLink } from '../../components/commons/back-link/back-link';
import { FormController } from '../../components/commons/form/controller/form-controller';
import { FileDropzone, FileDropzoneValue } from '../../components/commons/form/file-upload/file-dropzone';
import Path from '../../enums/path';
import { useAppContext } from '../../hooks/useApp';
import { AggregatorImportFilesStatus, ImportFileStatusEnum, ImportFileStatusObj } from './aggregator-import-files-status';

export function AggregatorImportFilesModule() {
  const { firebase } = useAppContext();

  const [loading, setLoading] = React.useState(false);
  const [prevLoading, setPrevLoading] = React.useState(false);
  const [results, setResults] = React.useState<ImportFileStatusObj[]>([]);
  const [rejected, setRejected] = React.useState<any[]>([]);
  const [importRecords, setImportRecords] = React.useState<ImportFileStatusObj[]>([]);

  // const handleChange = React.useCallback((d: any) => {
  //   if (Array.isArray(d.files)) {
  //     setLoaded(d.files.length > 0);
  //   }
  // }, []);

  const rejectedFiles = React.useMemo(() => {
    return rejected.map((x) => ({
      id: uuidv4(),
      file: x.file,
      errors: x.errors,
    }));
  }, [rejected])

  const refreshTransactionImport = React.useCallback((autorefresh: boolean = false) => {
    if (!firebase.user) return;
    if (!autorefresh) setPrevLoading(true);
    getTransactionImport({
      query: {
        page: 1,
        perPage: 20,
        uploadedBy: firebase.user.email ?? undefined,
      }
    })
      .then((d) => {
        const ir = d.data.data.records.map((r): ImportFileStatusObj => {
          let status: ImportFileStatusEnum;
          switch (r.status) {
            case 'done':
              status = ImportFileStatusEnum.SUCCESS;
              break;
            case 'done_with_error':
              status = ImportFileStatusEnum.ERROR;
              break;
            default:
              status = ImportFileStatusEnum.PENDING;
              break;
          }

          return {
            id: r.id,
            filename: r.filename,
            status,
            result: {
              totalInsert: r.totalInsert,
              error: r.totalError === 0 ? undefined : {
                message: `${r.totalError} error(s) received`
              },
            },
            fromHistory: true,
            updatedAt: moment(r.updatedAt).tz('Asia/Manila').toDate(),
          };
        });

        setImportRecords(ir);
      })
      .catch((error) => {
        log.error('getTransactionImport', error);
      })
      .finally(() => setPrevLoading(false));
  }, [firebase.user]);

  const updateStatus = React.useCallback((
    id: string,
    status: ImportFileStatusEnum,
    result?: Partial<ImportFileStatusObj>,
  ) => {
    setResults((curr) => curr.map((x) => {
      if (x.id === id) {
        log.debug('upload result', { ...x, ...result, status });
        return { ...x, ...result, status };
      }
      return x;
    }));
  }, []);

  const submitOneFile = React.useCallback(async (file: FileDropzoneValue) => {
    updateStatus(file.id, ImportFileStatusEnum.UPLOADING);
    await importAggregator({ data: file.file, async: 'true' })
      .then((result) => {
        const totalError = result?.totalError ?? 0;
        const hasError = totalError > 0;
        let r = result;
        const errors: any[] = [];
        (result?.errorList || []).forEach((item: any) => {
          const m = typeof item === 'string' ? item : item.message;
          const ex = errors.find((x: any) => x.message === m);
          if (!ex) {
            errors.push(typeof item === 'string' ? {
              message: m,
              count: 1,
            } : item);
          } else {
            ex.count += 1;
          }
        });
        if (hasError) {
          if (result?.errorList && Array.isArray(result?.errorList)) {
            r = result.errorList[0];
          }
          if (totalError > 1) {
            r = {
              errorCode: 'MULTIPLE_ERRORS',
              message: `${totalError} errors received`,
              errors,
            };
          }
        }
        r.linkAttached = result?.linkAttached;
        updateStatus(
          file.id,
          hasError ? ImportFileStatusEnum.ERROR : ImportFileStatusEnum.PENDING,
          {
            result: r,
            error: undefined,
          },
        );
      })
      .catch((err) => {
        updateStatus(file.id, ImportFileStatusEnum.ERROR, {
          result: err?.response?.data || err,
          error: err,
        });
      })
      .finally(() => {
        refreshTransactionImport();
      })
  }, [updateStatus, refreshTransactionImport]);

  const onSubmit = React.useCallback(async (d: any) => {
    if (loading) return;
    setLoading(true);
    const files = (d.files ?? []) as FileDropzoneValue[];
    log.debug('submitted files', files);
    setResults(files.map((x) => ({
      id: x.id,
      file: x.file,
      filename: x.file.name,
      status: ImportFileStatusEnum.PENDING,
    })));
    for (const i in files) {
      await submitOneFile(files[i]);
    }
    setLoading(false);
  }, [loading, submitOneFile]);

  const onDropRejected = React.useCallback((rejected: any[]) => {
    setRejected(rejected);
  }, []);

  // get latest uploads
  React.useEffect(() => {
    refreshTransactionImport();
  }, [refreshTransactionImport]);

  React.useEffect(() => {
    const pending = importRecords.filter((x) => x.status === ImportFileStatusEnum.PENDING);
    let i: ReturnType<typeof setTimeout>;

    if (pending.length > 0) {
      console.log('pending files found. refresh after a minute');
      i = setTimeout(() => {
        refreshTransactionImport(true);
      }, 1000 * 60 * 1);
    }

    return () => {
      if (i) clearTimeout(i);
    }
  }, [importRecords, refreshTransactionImport]);

  return (
    <>
      <BackLink list={[{ text: 'Summary', path: Path.AggregatedSummary }]} />
      <Intro title="Import Multiple Files" subtitle="Import Multiple Validated DSR Sales" />
      <FormController onSubmit={onSubmit}>
        <Stack direction='column' spacing={2}>
          <FileDropzone
            name='files'
            multiple
            hideFiles
            dropzoneOptions={{
              disabled: loading,
              accept: {
                'text/plain': ['.csv'],
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.csv'],
              },
              onDropRejected,
            }}
          />
          <AggregatorImportFilesStatus
            statuses={results}
            rejected={rejectedFiles}
            disabled={loading}
            hidePending
          />
          <Button type='submit' disabled={loading}>Submit</Button>
        </Stack>
        <Divider sx={{ p: '10px' }} />
        <Box sx={{ p: '10px' }}>
          <Typography variant='body1'>Previous Uploads</Typography>
        </Box>
        {prevLoading ? <CircularProgress /> : (
          <AggregatorImportFilesStatus
            key={'previous'}
            statuses={[]}
            previousUploads={importRecords}
            hideDropZoneFiles
          />
        )}
      </FormController>
    </>
  );
}
