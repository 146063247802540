import { DefaultLayout } from '../components/layout/twentyfour/default';
import { LocationImportModule } from '../modules/location/location-import.module';

export function LocationImportPage() {
  return (
    <DefaultLayout>
      <LocationImportModule />
    </DefaultLayout>
  )
}
