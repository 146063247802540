import axios from 'axios';
import ApiPath from '../enums/api-path';
import { BasicQueryApi, ListApiFn, ListApiProps } from './api.type';

export interface GetAggregatedQueryApi extends BasicQueryApi {
  postingDate?: string;
  stationCode?: string;
  syncStatus?: string;
  uploadedBy?: string;
  batchId?: string;
}
export const getAggregated: ListApiFn = async (
  props: ListApiProps<GetAggregatedQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(`${ApiPath.AggregatorAggregated}`, {
    params: query,
  });
  return res;
};

export const exportAggregated: ListApiFn = async (
  props: ListApiProps<GetAggregatedQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(`${ApiPath.AggregatorAggregatedExport}`, {
    params: query,
    responseType: 'blob',
  });
  return res;
};

export interface GetAggregatedSummaryByStatusQueryApi extends BasicQueryApi {
  postingDate?: string;
  stationCode?: string;
  syncStatus?: string;
  uploadedBy?: string;
}
export const getAggregatedSummaryByStatus: ListApiFn = async (
  props: ListApiProps<GetAggregatedSummaryByStatusQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(
    `${ApiPath.AggregatorAggregatedSummaryByStatus}`,
    { params: query }
  );
  return res;
};

export const exportAggregatedSummaryByStatus: ListApiFn = async (
  props: ListApiProps<GetAggregatedSummaryByStatusQueryApi>
) => {
  let { query } = props;
  const res = await axios.get(
    `${ApiPath.AggregatorAggregatedSummaryByStatusExport}`,
    {
      params: query,
      responseType: 'blob',
    }
  );
  return res;
};

export const getTransactionByCreatedAtUploadedByStatus: ListApiFn = async (
  props: ListApiProps
) => {
  const { query } = props;
  const res = await axios.get(
    `${ApiPath.AggregatorTransactionByCreatedAtUploadedByStatus}`,
    { params: query }
  );
  return res;
};

export interface GetTransactionWithSyncStatusQueryApi extends BasicQueryApi {
  batchId?: string;
  stationCode?: string;
  syncStatus?: string;
  uploadedBy?: string;
}
export const getTransactionWithSyncStatus: ListApiFn = async (
  props: ListApiProps<GetTransactionWithSyncStatusQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(`${ApiPath.AggregatorTransactionWithStatus}`, {
    params: {
      query: { ...query, filter: undefined },
    },
  });
  return res;
};

export type ImportAggregatorProps = {
  data: File;
  remarks?: string;
  async?: 'true' | 'false';
  /** @deprecated */
  errorReason?: string;
};
export const importAggregator = async (props: ImportAggregatorProps) => {
  const { data, errorReason, remarks, async } = props;
  const formData = new FormData();
  formData.append('data', data);
  if (remarks || errorReason) {
    formData.append('remarks', remarks || errorReason || '');
    formData.append('errorReason', remarks || errorReason || '');
  }
  if (async) {
    formData.append('async', async);
  }
  return await axios
    .post(`${ApiPath.Aggregator}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    })
    .then((r) => r.data.data);
};

export interface GetTransactionImportQueryApi extends BasicQueryApi {
  id?: string;
  uploadedBy?: string;
}
export type GetTransactionImportResponseApi = {
  id: string;
  filename: string;
  s3Location: string;
  status: 'new' | 'queued' | 'done' | 'done_with_error',
  uploadedBy: string;
  totalInsert: number;
  totalError: number;
  remarks?: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
export const getTransactionImport
  : ListApiFn<GetTransactionImportQueryApi, GetTransactionImportResponseApi>
  = async (props) => {
    const { query } = props || {};
    const res = await axios.get(`${ApiPath.AggregatorTransactionImport}`, {
      params: query,
    });
    return res;
  };

export const getTransactionImportDetails = async (props: { id: string }) => {
  const { id } = props;
  const res = await axios.get(`${ApiPath.AggregatorTransactionImportDetails}`, {
    params: { id },
  });
  return res.data.data;
};

export const postTransactionImportToQueue = async (props: { idIn: string[] }) => {
  const { idIn } = props;
  const res = await axios.post(`${ApiPath.AggregatorTransactionImportToQueue}`, {
    idIn,
  });
  return res.data.data;
};
