import axios from "axios";
import qs from 'qs';
import ApiPath from "../enums/api-path";
import { BasicQueryApi, ListApiFn } from "./api.type";

export interface GetLocationQueryApi extends BasicQueryApi {
  locationId?: number;
  subsidiary?: string;
  stationCode?: string;
  stationCodeIn?: string[];
}

export type LocationResponse = {
  gcashBranchName?: string[],
  locationId: number,
  mayaMid?: string[],
  stationCode: string,
  stationName: string,
  subsidiary: string,
  subsidiaryId: number;
  shiftCount: number,
  shift1Start: string,
  shift1End: string,
  shift2Start?: string | null,
  shift2End?: string | null,
  shift3Start?: string | null,
  shift3End?: string | null,
}

export const getLocationList: ListApiFn<GetLocationQueryApi, LocationResponse> = async (props) => {
  const { query = {} } = props;
  // console.log('query', qs.stringify(query, { arrayFormat: 'indices' }));
  const r = await axios.get(`${ApiPath.Location}`, {
    params: query,
    maxBodyLength: Infinity,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma' }),
  });
  return r;
};

export type UpdateLocationRecord = {
  locationId: number;
  stationCode: string;
  subsidiary: string;
  shiftCount?: number | null;
  shift1Start: string;
  shift1End: string;
  shift2Start?: string | null;
  shift2End?: string | null;
  shift3Start?: string | null;
  shift3End?: string | null;
};

export type UpdateLocationRequest = {
  records: UpdateLocationRecord[],
}

export type UpdateLocationResponse = {
  message: "Location update/s completed",
  data: {
    updated: number,
    failed: number,
    errors: {
      locationId: number,
      message: string;
    }[];
  }
}

export const updateLocation = async (props: UpdateLocationRequest) => {
  const { records } = props;
  const r = await axios.post<UpdateLocationResponse>(`${ApiPath.Location}`, records);
  return r;
};
