import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment-timezone';
import { ImportFileStatusEnum } from './aggregator-import-files-status';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'delete',
      header: '',
      cell: (info) => {
        const row = info.row.original as any;
        if (row.fromHistory) return <></>;
        return (
          <IconButton
            title={'Remove File'}
            disabled={row.disabled}
            onClick={row.deleteFile}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
    {
      id: 'filename',
      accessorKey: 'filename',
      header: 'Filename',
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (info) => {
        const row = info.row.original as any;
        if (!row.status) return <></>;

        let status: string = row.status;
        let icon: JSX.Element | undefined = <></>;
        let color: any = 'default';
        switch (row.status) {
          case ImportFileStatusEnum.SUCCESS:
            icon = <DoneIcon />;
            color = 'success'
            break;
          case ImportFileStatusEnum.ERROR:
            icon = <ErrorOutlineIcon />;
            color = 'error';
            break;
          case ImportFileStatusEnum.PENDING:
            const now = moment().tz('Asia/Manila');
            const updatedAt = moment(row.updatedAt);
            const diff = now.diff(updatedAt, 'minutes');
            icon = <MoreHorizIcon />;
            if (diff > 15) {
              icon = <ReplayIcon />;
              status = 'RETRY';
            };
            break;
          case ImportFileStatusEnum.UPLOADING:
            icon = <CircularProgress size={'15px'} />;
            color = 'info';
            break;
          default:
            icon = undefined;
            break;
        }

        return (
          <Chip
            color={color}
            icon={icon}
            size={'small'}
            label={status.toUpperCase()}
            key={row.id}
            sx={{ mb: '10px', mr: '10px' }}
            onClick={status === 'RETRY' ? row.retryPostToQueue : undefined}
          />
        )
      }
    },
    {
      id: 'totalInsert',
      header: 'Records Uploaded',
      cell: (info) => {
        const row = info.row.original as any;
        if (!row.result?.totalInsert) return <></>;
        return row.result.totalInsert;
      },
    },
    {
      id: 'result',
      // accessorKey: 'result',
      header: 'Result',
      cell: (info) => {
        const row = info.row.original as any;
        if (!row.result) return <></>;
        const result = row.result;
        let message = row.status === ImportFileStatusEnum.SUCCESS
          ? `${result.totalInsert} row(s) successfully saved;`
          : undefined;

        if (row.status === ImportFileStatusEnum.ERROR) {
          message = result?.message || result?.error?.message || 'Unknown Error';
        }

        let downloadErrors = <></>;
        if (result?.linkAttached) {
          downloadErrors = (
            <>
              {'. '}
              <a href={result.linkAttached}>
                Download Error List
              </a>
            </>
          )
        }
        return (
          <>
            {message}{downloadErrors}
          </>
        );
      },
    },
    {
      id: 'details',
      accessorKey: 'details',
      header: 'Show Details',
      cell: (info) => {
        const row = info.row.original as any;
        if (row.status !== ImportFileStatusEnum.SUCCESS
          && row.status !== ImportFileStatusEnum.ERROR) return <></>;
        return (
          <IconButton
            title={'Show Details'}
            disabled={row.disabled}
            onClick={row.showStatus}
          >
            <InfoIcon />
          </IconButton>
        );
      },
    },
  ];

  return columns;
}
