import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';
import { UpdateLocationRecord } from '../../apis/location.api';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export type ImportCsvFormat = {
  'Entity': string;
  'Station Code': string;
  'Station Name'?: string;
  'Number of Shifts'?: string;
  'Shift 1 Start'?: string;
  'Shift 1 End'?: string;
  'Shift 2 Start'?: string;
  'Shift 2 End'?: string;
  'Shift 3 Start'?: string;
  'Shift 3 End'?: string;
}

export enum UploadStatusEnum {
  NO_CHANGE = 'NO_CHANGE',
  NO_MATCH = 'NO_MATCH',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  ERROR = 'ERROR',
}

export interface UpdateLocationRecordWithStatus extends UpdateLocationRecord {
  stationName?: string;
  status: UploadStatusEnum;
  error?: string;
}

export function useColumns() {
  const columns: ColumnDef<UpdateLocationRecordWithStatus>[] = [
    {
      id: 'id',
      header: 'ID',
      accessorKey: 'locationId',
      cell: (info) => {
        const value = info.getValue();
        return <>{value || '-'}</>
      }
    },
    {
      id: 'station code',
      header: 'Station Code',
      accessorKey: 'stationCode',
    },
    {
      id: 'station name',
      header: 'Station Name',
      accessorKey: 'stationName',
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (info) => {
        const row = info.row.original as any;
        if (!row.status) return <></>;

        let status: string = row.status;
        let icon: JSX.Element | undefined = <></>;
        let color: any = 'default';
        switch (row.status) {
          case UploadStatusEnum.UPDATED:
            icon = <DoneIcon />;
            color = 'success'
            break;
          case UploadStatusEnum.ERROR:
            icon = <ErrorOutlineIcon />;
            color = 'error';
            break;
          case UploadStatusEnum.PENDING:
            icon = <CircularProgress size={'15px'} />;
            color = 'info';
            break;
          case UploadStatusEnum.NO_MATCH:
            icon = <ErrorOutlineIcon />;
            color = 'error';
            break;
          case UploadStatusEnum.NO_CHANGE:
            icon = <DoneIcon />;
            color = 'info';
            break;
          default:
            icon = undefined;
            break;
        }

        return (
          <Chip
            color={color}
            icon={icon}
            size={'small'}
            label={startCase(status.toLowerCase())}
            key={row.id}
            sx={{ mb: '10px', mr: '10px' }}
            onClick={status === 'RETRY' ? row.retryPostToQueue : undefined}
          />
        )
      }
    },
    {
      id: 'error',
      header: 'Error',
      accessorKey: 'error',
    },
  ];

  return columns;
}
