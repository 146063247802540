import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import log from 'loglevel';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getTransactionImportDetails, postTransactionImportToQueue } from '../../apis/aggregator.api';
import { DataTableStandalone } from '../../components/commons/data-table/data-table-standalone';
import { useFormController } from '../../components/commons/form/controller/form-controller';
import { FileDropzoneValue } from '../../components/commons/form/file-upload/file-dropzone';
import { useColumns } from './aggregator-import-files-status-column';

export enum ImportFileStatusEnum {
  PENDING = 'pending',
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ImportFileStatusObj = {
  id: string;
  file?: File;
  filename: string;
  status: ImportFileStatusEnum;
  result?: any;
  error?: any;
  fromHistory?: boolean;
  updatedAt?: Date;

}

export type AggregatorImportFilesStatusProps = {
  statuses: ImportFileStatusObj[];
  rejected?: any[];
  previousUploads?: ImportFileStatusObj[];
  disabled?: boolean;
  hideDropZoneFiles?: boolean;
  hidePending?: boolean;
}

type ListRecordType = {
  id: string,
  filename: string,
  status: any,
  error: any,
  result: any,
  file?: File,
  disabled?: boolean,
  fromHistory?: boolean,
  updatedAt?: Date,
  showStatus?: (...p: any[]) => void,
  deleteFile?: (...p: any[]) => void,
  retryPostToQueue?: (...p: any[]) => void,
}

export function AggregatorImportFilesStatus(props: AggregatorImportFilesStatusProps) {
  const {
    disabled,
    statuses,
    rejected,
    previousUploads,
    hideDropZoneFiles = false,
    hidePending = false,
  } = props;

  const { setValue, getValues, watch } = useFormController();
  const [files, setFiles] = React.useState<FileDropzoneValue[]>([]);
  const [result, setResult] = React.useState<ImportFileStatusObj>();
  const [retried, setRetried] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [rejectedFiles, setRejectedFiles] = React.useState<any[]>(rejected || []);

  const handleClose = () => setOpen(false);

  const errorObj = React.useMemo(() => {
    const code = result?.result?.errorCode ? `[{result?.result?.errorCode || ''}]` : '';
    const message = result?.result?.message
      || result?.error?.message
      || (result?.result?.totalError ? `${result?.result?.totalError} records rejected` : undefined)
      || 'Unknown Error';

    return {
      code,
      message,
    }
  }, [result]);

  const onDelete = React.useCallback((id: string) => () => {
    const f = getValues('files') as FileDropzoneValue[];
    setValue('files', f.filter((x) => x.id !== id));
    setRejectedFiles((r) => {
      r.forEach((x) => {
        if (x.id === id) {
          x.display = false;
        }
      })
      return r;
    });
  }, [getValues, setValue]);

  const showStatus = React.useCallback((id: string, arr?: any[]) => () => {
    const list = arr || statuses;
    const selected = list.find((x) => x.id === id);
    log.debug('selected', selected);
    setResult(selected);
    setOpen(true);
    if (selected.fromHistory) {
      console.log('get transaction import details ...');
      setLoading(true);
      getTransactionImportDetails({ id: selected.id })
        .then((res) => {
          console.log('result', res)
          setResult({ ...selected, result: res });
        })
        .catch((err) => {
          console.log(err);
          setResult({ ...selected, error: err.response.data });
        })
        .finally(() => setLoading(false));
    }
    log.debug('selected', selected);
  }, [statuses]);

  const retryPostToQueue = React.useCallback((id: string) => () => {
    setRetried((r) => [...r, id]);
    postTransactionImportToQueue({ idIn: [id] });
  }, []);

  const columns = useColumns();
  const records = React.useMemo(() => {
    const rs: ListRecordType[] = [];
    if (!hideDropZoneFiles) {
      const fs = (getValues('files') ?? []) as FileDropzoneValue[];
      log.trace('same length', fs.length === files.length); // TODO: not synced with watch
      fs.forEach((f) => {
        const s = statuses.find((x) => x.id === f.id);

        if (hidePending && s?.status === ImportFileStatusEnum.PENDING) {
          onDelete(f.id)();
          return;
        }

        rs.push({
          id: f.id,
          filename: f.file.name,
          status: s?.status,
          error: s?.error,
          result: s?.result,
          file: s?.file,
          disabled,
          showStatus: showStatus(f.id, statuses),
          deleteFile: onDelete(f.id),
        });
      });

      rejectedFiles
        .filter((x) => x.display !== false)
        .forEach((r) => {
          const id = r.id || uuidv4();
          rs.push({
            id,
            filename: r.file.name,
            status: ImportFileStatusEnum.ERROR,
            error: undefined,
            result: { message: 'Rejected. Invalid File Type' },
            file: r.file,
            disabled,
            deleteFile: onDelete(id),
          });
        });
    }

    previousUploads?.forEach((s) => {
      rs.push({
        id: s.id,
        filename: s.filename,
        status: s.status,
        error: s.error,
        result: s.result,
        file: s.file,
        disabled,
        fromHistory: true,
        updatedAt: retried.includes(s.id) ? new Date() : s.updatedAt,
        showStatus: showStatus(s.id, previousUploads),
        retryPostToQueue: retryPostToQueue(s.id),
      });
    });

    return rs;
  }, [
    getValues,
    showStatus,
    retryPostToQueue,
    onDelete,
    statuses,
    disabled,
    files,
    rejectedFiles,
    previousUploads,
    hideDropZoneFiles,
    hidePending,
    retried,
  ]);

  React.useEffect(() => {
    const { unsubscribe } = watch((data: any) => {
      log.debug(data);
      setFiles(data.files ?? []);
    });
    return () => unsubscribe();
  }, [watch]);

  return (
    <Box>
      {records.length === 0 ? undefined : (
        <DataTableStandalone
          data={records}
          columns={columns}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>
          {String(result?.status).toUpperCase()} :: {result?.filename}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!loading && result?.status === ImportFileStatusEnum.SUCCESS ? (
              <>{`${result?.result?.totalInsert} row(s) successfully saved;`}</>
            ) : undefined}
            {!loading && result?.status === ImportFileStatusEnum.ERROR ? (
              <Box>
                <Box sx={{ fontSize: '120%' }}><b>Error:</b></Box>
                <Box>
                  <span>{errorObj.code}</span>
                  {errorObj.code && <span>&nbsp;</span>}
                  <span>{errorObj.message}</span>
                  <ul>
                    {(result?.result?.errors || result?.result?.errorList || [])
                      .slice(0, 50)
                      .map(
                        (item: any, index: number) => (
                          <li key={index}>
                            {item.message}
                            {item.count > 1 ? ` [${item.count} errors]` : ''}
                          </li>
                        )
                      )}
                  </ul>
                </Box>
              </Box>
            ) : (loading ? <CircularProgress /> : <></>)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!loading && result?.status === ImportFileStatusEnum.ERROR
            && result.result?.linkAttached
            ? (
              <Button href={result.result.linkAttached}
                variant='outlined' color='error'>
                Download Errors
              </Button>
            ) : undefined}
          <Button onClick={handleClose} variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
